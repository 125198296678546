import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Navigate, Route, Routes, useLocation } from "react-router-dom";
import reportWebVitals from './reportWebVitals';
/* META LEVEL APP PAGE */
import App from './App';

/* MOST GENERIC CSS */
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import './styles/bootstrap-modal.css'; // Import the compiled CSS
import { ReactNotifications } from 'react-notifications-component';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

function MainRoutes() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<App />} />
        <Route path="/riot.txt" />
        <Route path='*' element={<Navigate replace to='/' />} />
      </Routes>
    </BrowserRouter>
  )
}

root.render(
  <React.StrictMode>
    <ReactNotifications />
    <MainRoutes />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
